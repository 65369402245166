
import { dispatchSubmitResetPassword } from '@/store/main/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ResetPasswordModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public loadingState: any = {
    submit: false,
  };
  public resetEmail: string = '';
  public formError: boolean = false;
  public resetError: boolean = false;
  public resetOK: boolean = false;

  public validateForm() {
    if (!this.resetEmail) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public async handleSubmit() {
    // this.resetError = false;
    // if (await this.validateForm()) {
    //   this.loadingState.submit = true;
    //   const response = await dispatchSubmitResetPassword(this.$store, {email: this.resetEmail});
    //   if (response) {
    //     this.resetOK = true;
    //   } else {
    //     this.resetError = true;
    //     // this.$toast.error(this.$t('Error').toString());
    //   }
    //   this.loadingState.submit = false;
    // }
  }

  public closeModal() {
    this.$emit('close-modal');
  }
}
