var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('main',{staticClass:"main main-login main_page_login"},[_vm._m(0),_c('div',{staticClass:"main-login__col main-login__col_type_form"},[_c('h1',{staticClass:"main-login__title"},[_vm._v(_vm._s(_vm.$t("Sign in to the system")))]),_c('form',{staticClass:"form form-login",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form__input",class:{
            form__input_type_error:
              _vm.loginError ||
              (_vm.formError && !_vm.email) ||
              (_vm.email && !/(.+)@(.+){1,}\.(.+){2,}/.test(_vm.email)),
          },attrs:{"type":"text","placeholder":"Email","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('label',{staticClass:"form__label form__label_type_password"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form__input",class:{
              form__input_type_error: _vm.loginError || (_vm.formError && !_vm.password),
            },attrs:{"type":"password","placeholder":_vm.$t('Password'),"required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"main-user__agreement",staticStyle:{"margin-bottom":"15px","cursor":"pointer"},on:{"click":_vm.handleOpenResetPassword}},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("Forgot password"))+"? ")])]),_c('button',{staticClass:"form__btn form__btn_type_page",attrs:{"type":"submit","disabled":_vm.loadingState.submit}},[_vm._v(" "+_vm._s(_vm.$t("Sign In"))+" "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.submit),expression:"loadingState.submit"}],attrs:{"small":""}})],1),(_vm.formError)?_c('p',{staticClass:"form__error"},[_vm._v(" "+_vm._s(_vm.$t("There is no such email"))+" ")]):_vm._e(),(_vm.loginError)?_c('p',{staticClass:"form__error"},[_vm._v(" "+_vm._s(_vm.$t("Invalid username/password pair"))+" ")]):_vm._e()])])]),(_vm.showMFAModal)?_c('MFAVerificationModal',{attrs:{"code":_vm.code,"email":_vm.email,"password":_vm.password},on:{"close":_vm.handleMFAModalClose,"verified":_vm.handleMFASuccess}}):_vm._e(),_c('ResetPasswordModal',{attrs:{"modalShow":_vm.showResetPasswordModal},on:{"close-modal":_vm.handleCloseResetPassword}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"main-login__col main-login__col_position_center main-login__col_type_bg"},[_c('img',{staticClass:"main-login__img main-login__img_page_login",attrs:{"src":require("@/assets/images/login-img.png"),"alt":"loog"}})])
}]

export { render, staticRenderFns }