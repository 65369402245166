
import { Component, Vue } from "vue-property-decorator";
import { dispatchLogIn } from "@/store/main/actions";
import ResetPasswordModal from "@/components/Landing/ResetPasswordModal.vue";
import MFAVerificationModal from "./MFAVerificationModal.vue";

@Component({
  components: {
    ResetPasswordModal,
    MFAVerificationModal,
  },
})
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public code: any = null;
  public formError: boolean = false;
  public loginError: boolean = false;
  public loadingState: any = {
    submit: false,
  };
  public showResetPasswordModal: boolean = false;
  public showMFAModal: boolean = false;

  public validateData() {
    if (!this.email || !this.password) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public handleOpenResetPassword() {
    this.$router.push("/recover-password");
    // this.showResetPasswordModal = true;
  }

  public handleCloseResetPassword() {
    this.showResetPasswordModal = false;
  }

  public async handleSubmit() {
    this.loginError = false;
    if (!this.validateData()) {
      return null;
    }
    const result = await dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
      code: this.code,
      timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (result.ok) {
      this.$router.push("/dashboard");
    } else if (result.mfa) {
      this.showMFAModal = true;
    } else {
      this.loginError = true;
      // this.$toast.error(this.$t('Error').toString());
    }
  }

  public handleMFAModalClose() {
    this.showMFAModal = false;
  }

  public async handleMFASuccess() {
    this.showMFAModal = false;
    this.$router.push("/dashboard");
  }
}
