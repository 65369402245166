
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispatchMfaLogIn } from "@/store/main/actions";

@Component
export default class MFAVerificationModal extends Vue {
  @Prop({ required: true }) readonly email!: string;
  @Prop({ required: true }) readonly password!: string;
  @Prop({ required: true }) readonly code!: any;

  public mfaCode: string = "";
  public verificationError: boolean = false;
  public loading: boolean = false;

  mounted() {
    (this.$refs.mfaInput as HTMLElement).focus();
  }

  public async handleVerify() {
    if (!this.mfaCode) return;

    this.verificationError = false;
    this.loading = true;

    try {
      const result = await dispatchMfaLogIn(this.$store, {
        username: this.email,
        password: this.password,
        code: this.code,
        timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        mfa_code: this.mfaCode,
      });
      if (result) {
        this.$emit("verified");
      } else {
        this.verificationError = true;
      }
    } catch (error) {
      this.verificationError = true;
    } finally {
      this.loading = false;
    }
  }
}
